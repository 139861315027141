import { Grid } from "@progress/kendo-react-grid";
import styled from "styled-components";
export const Wrap = styled.div`
  .k-animation-container {
    width: 100%;
  }
`;

export const Flex = styled.div`
  display: flex;
`;

export const DialogForm = styled.div`
  max-height: 700px;
`;

export const Input = styled.input`
  width: ${(props) => (props.width ? props.width : "250px")};
  height: 35px;
  border-radius: 0.2rem;
  border: 1px solid #ededed;
  padding-left: 10px;
  &:focus {
    outline: 1px solid #4583ee;
    box-shadow: 0px 0px 2px red;
  }
`;

export const Table = styled.table`
  width: 100%;
  height: 150px;
  border-top: 1px solid #444;
  border-bottom: 1px solid #444;
  border-collapse: collapse;
`;

export const Th = styled.th`
  background: #f2f4f6;
  text-align: left;
  padding: 10px;
  font-size: 15px;
  font-weight: 500;
  color: #636a75;
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
`;

export const Td = styled.td`
  border: 1px solid #e2e5e8;
  border-left: none;
  border-right: none;
  padding: 10px;
`;

export const InputWrap = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const GridStyled = styled(Grid)`
  flex: 1;
  border-right: ${(props) => (props.isLast ? "" : "2px dotted")};
  border-left: ${(props) => (props.isFirst ? "" : "2px dotted")};
  &:first-child {
    border-right: none;
  }
`;
