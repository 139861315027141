import React, { useEffect, useMemo, useState } from "react";
import {
  Drawer,
  DrawerContent,
  DrawerItem,
} from "@progress/kendo-react-layout";
import { Button } from "@progress/kendo-react-buttons";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { signOut, updateScreen } from "../../store/authReducer";
import logo_new from "../../assets/images/logo_new.png";
import logo_biz from "../../assets/images/gold_biz_logo.png";
import { AiFillGold } from "react-icons/ai";
import { Tooltip } from "@progress/kendo-react-tooltip";
import { FaExchangeAlt } from "react-icons/fa";
import {
  FcBullish,
  FcViewDetails,
  FcPortraitMode,
  FcCalculator,
  FcShop,
  FcSurvey,
  FcPaid,
  FcDownload,
  FcReadingEbook,
  FcNext,
  FcMoneyTransfer,
} from "react-icons/fc";

const ICON = {
  FcBullish,
  FcViewDetails,
  FcPortraitMode,
  FcCalculator,
  FcShop,
  FcSurvey,
  FcPaid,
  FcDownload,
  FcReadingEbook,
  FcNext,
  FcMoneyTransfer,
  AiFillGold,
  FaExchangeAlt,
};

const ExpandButton = styled(Button)`
  border-radius: 50%;
  background-color: #ffffff;
  cursor: pointer;
  // border: 1px solid #ff9d5b;
  z-index: 1;
  box-shadow: -1px 1px 1px 1px lightgrey;

  width: 40px;
  height: 40px;
  top: 60px;
  transition: all 200ms ease 0s;
  span {
    font-size: 25px;
  }
  position: absolute;
  bottom: 0px;
`;

const DrawerWrapper = styled(Drawer)`
  height: 100%;
  width: 100%;
  transition: 0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91) !important;
  overflow-x: auto;
  ${ExpandButton} {
    left: ${(props) => (props.expanded ? props.width : props.miniWidth) - 20}px;
    transform: rotateY(${(props) => (props.expanded ? 0.5 : 0)}turn);
  }
`;

/**
 * SHOP : 대리점
 * ASSOCIATE : 제휴사
 * BIZ : 위탁매입
 */

const screenTypeConfig = {
  BIZ: {
    logo: logo_biz,
    name: "Gold Biz",
    color: "#005F99",
    themeColor: "#005f99",
  },
  SHOP: {
    logo: logo_new,
    name: "금방금방",
    color: "#df5830",
    themeColor: "#ff9d5b",
  },
};

const items = [
  { route: "/" },
  {
    text: "회원 관리",
    selected: true,
    route: "/member",
    icon: "FcPortraitMode",
    role: "SHOP",
  },
  {
    text: "통합거래내역",
    route: "/transaction",
    icon: "FcViewDetails",
    role: "SHOP",
  },
  {
    text: "매수/매도",
    route: "/buysell",
    icon: "FcBullish",
    role: "SHOP",
  },
  {
    text: "감정평가",
    route: "/appraisal",
    icon: "FcSurvey",
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    text: "지체상금",
    route: "/penalty",
    icon: "FcMoneyTransfer",
    role: "SHOP,BIZ",
  },
  {
    text: "실물인출",
    route: "/goodswithdrawal",
    icon: "AiFillGold", // style={{ fill: "#ffeb3b" }}
    iconStyle: { fill: "#ffeb3b" },
    role: "SHOP,ASSOCIATE",
  },
  {
    text: "안심직거래",
    route: "/safe-trade",
    icon: "FcPaid",
    role: "SHOP",
  },
  {
    text: "대리점관리",
    route: "/shop",
    icon: "FcShop",
    role: "SHOP,BIZ",
  },
  {
    text: "정산관리",
    route: "/adjustment",
    icon: "FcCalculator",
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    separator: true,
  },
  {
    text: "내 정보",
    route: "/manager",
    icon: "FcReadingEbook", // <FcReadingEbook style={{ fontSize: "20px" }} />,
    iconStyle: { fontSize: "20px" },
    role: "SHOP,ASSOCIATE,BIZ",
  },
  {
    text: "로그아웃",
    route: "/logout",
    icon: "FcDownload",
    iconStyle: { transform: "rotate(270deg)" }, //  <FcDownload style={{ transform: "rotate(270deg)" }} />,
    role: "SHOP,ASSOCIATE,BIZ",
  },
];

const getMenuByRole = (role, screenType) => {
  if (!role) return [];

  const validRoles = ["SHOP", "ASSOCIATE", "BIZ"];
  const filteredRole = validRoles.find((item) => role.includes(item));
  let menus = [];

  if (filteredRole) {
    menus = [
      { route: "/" },
      ...items.filter((menu) => menu.role && menu.role.includes(filteredRole)),
    ];
  }

  if (role === "ROLE_MANAGER_SHOP_GBGB_BIZ") {
    menus = [
      { route: "/" },
      ...items.filter(
        (menu) =>
          menu.role &&
          (screenType === "SHOP"
            ? menu.role.includes("SHOP")
            : menu.role.includes("BIZ"))
      ),
    ];
    menus.push({
      text: `${screenType === "SHOP" ? "BIZ 전환" : "SHOP 전환"}`,
      route: "/switch",
      role: "BIZ",
      icon: "FaExchangeAlt", // <FaExchangeAlt />,
    });
  }
  return menus;
};

const DrawerLayout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(true);
  const authReducer = useSelector((s) => s.auth);
  const { role } = authReducer;
  const screenType = localStorage.getItem("screenType");
  const menus = useMemo(
    () => getMenuByRole(role, screenType),
    [role, screenType]
  );

  useEffect(() => {
    const { themeColor } =
      screenTypeConfig[screenType] || screenTypeConfig.SHOP;

    document.documentElement.style.setProperty(
      "--kendo-background-color",
      themeColor
    );
  }, [screenType]);

  const onSelect = (e) => {
    const { route } = e.itemTarget.props;
    if (route === "/logout") {
      dispatch(signOut());
    } else if (route === "/switch") {
      if (role === "ROLE_MANAGER_SHOP_GBGB_BIZ") {
        const newScreen = screenType === "SHOP" ? "BIZ" : "SHOP";
        dispatch(updateScreen(newScreen));
        navigate(newScreen === "SHOP" ? "/member" : "/appraisal", {
          replace: true,
          state: { ...location.state },
        });
      }
    } else {
      navigate(route, {
        replace: false,
        state: { ...location.state },
      });
    }
  };

  const CustomItem = (props) => {
    if (props.route === "/") {
      const {
        logo,
        name: logoName,
        color: logoColor,
      } = screenTypeConfig[screenType] || screenTypeConfig.SHOP;

      return expanded ? (
        <>
          <LogoItem {...props}>
            <img src={logo} alt="logo" style={{ width: "100%" }} />
          </LogoItem>
          <div
            style={{
              width: expanded ? "139px" : "48px",
              transition: "0.15s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              height: "50px",
              position: "absolute",
              bottom: "0px",
              borderTop: "1px solid #ededed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <FcNext
              style={{ transform: `rotateY(${expanded ? 0.5 : 0}turn)` }}
            />
          </div>
        </>
      ) : (
        <>
          <span
            style={{
              fontSize: "13px",
              fontWeight: "900",
              color: logoColor,
              textAlign: "center",
            }}
          >
            {logoName}
          </span>
          <div
            style={{
              width: expanded ? "139px" : "48px",
              transition: "0.15s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              height: "50px",
              position: "absolute",
              bottom: "0px",
              borderTop: "1px solid #ededed",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 999,
            }}
            onClick={() => {
              setExpanded(!expanded);
            }}
          >
            <FcNext
              style={{ transform: `rotateY(${expanded ? 0.5 : 0}turn)` }}
            />
          </div>
        </>
      );
    }

    const iconItem =
      menus.find((item) => item.route === props.route)?.icon || "FcBullish";

    let style = {};

    if (!expanded) {
      style = {
        display: "flex",
        width: "100%",
        justifyContent: "center",
      };
    }
    console.log(props);
    return (
      <DrawerItem style={{ ...style, position: "relative" }} {...props}>
        {expanded ? (
          <div
            style={{
              transition: "0.5s cubic-bezier(0.6, 0.05, 0.28, 0.91)",
              whiteSpace: "nowrap",
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <ItemIcon>
              {ICON[iconItem]({ style: { ...props.iconStyle } })}
            </ItemIcon>
            <span>{props.text}</span>
          </div>
        ) : (
          <Tooltip openDelay={100} anchorElement="target" position="right">
            <div
              title={props.text}
              style={{ width: "49px", textAlign: "center" }}
            >
              <ItemIcon title={props.text}>
                {ICON[iconItem]({ style: { ...props.iconStyle } })}
              </ItemIcon>
            </div>
          </Tooltip>
        )}
      </DrawerItem>
    );
  };

  return (
    <>
      <DrawerWrapper
        expanded={expanded}
        position="start"
        mode="push"
        mini={true}
        onSelect={onSelect}
        width={expanded ? 140 : 49}
        miniWidth={49}
        items={menus.map((item) => ({
          ...item,
          selected: item.route === location.pathname,
        }))}
        item={CustomItem}
      >
        <DrawerContent style={{ height: "100%" }}>
          <Outlet />
        </DrawerContent>
      </DrawerWrapper>
      {/* <ExpandButton
        onClick={() => setExpanded(!expanded)}
        icon="arrow-chevron-right"
      /> */}
    </>
  );
};

const LogoItem = styled(DrawerItem)`
  background: white !important;
  &:focus {
    box-shadow: none;
  }
`;
const ItemIcon = styled.span`
  width: 20px;
  height: 20px;
  align-self: center;
`;

export default DrawerLayout;
