import React, { useEffect, useState, useRef, useCallback } from "react";

import {
  Grid,
  GridColumn,
  GRID_COL_INDEX_ATTRIBUTE,
} from "@progress/kendo-react-grid";
import * as APIS from "../../libs/apis";
import { useDispatch, useSelector } from "react-redux";
import { showLoading, hideLoading } from "../../store/loadingReducer";
import { hideAlert, showAlert } from "../../store/alertReducer";
import { showImageViewer } from "../../store/imageViewerReducer";
import consts from "../../libs/consts";
import {
  formatTime,
  formatPhone,
  numFormat,
  getPurityLabel,
  numberWithCommas,
  objToQueryStr,
} from "../../libs/utils";
import {
  RadioButton,
  RadioGroup,
  Input as KendoInput,
} from "@progress/kendo-react-inputs";
import AdminConfirmButton from "../../components/button/AdminConfirmButton";
import Button from "../../components/button/Button";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment-timezone";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { Dialog, DialogActionsBar } from "@progress/kendo-react-dialogs";
import cloneDeep from "lodash.clonedeep";
import ComponentToPrint from "./ComponentToPrint";
import { useReactToPrint } from "react-to-print";
import BizTransactionModal from "./BizTransactionModal";
import {
  Td,
  Th,
  Wrap,
  Flex,
  DialogForm,
  Input,
  Table,
  InputWrap,
  FlexContainer,
  GridStyled,
} from "./AppraisalDetail.css";

const AppraisalDetail = ({ id, fetchList, serviceType, bizPurchasePrice }) => {
  String.prototype.replaceAt = function (index, replacement) {
    if (index >= this.length) {
      return this.valueOf();
    }
    var chars = this.split("");
    chars[index] = replacement;
    return chars.join("");
  };
  const authReducer = useSelector((s) => s.auth);
  let { screenType } = authReducer;
  const isShop = screenType === "SHOP";
  const dispatch = useDispatch();
  const [detailData, setDetailData] = useState();
  const [weightRates, setWeightRates] = useState([]);
  const [silverGramPrice, setSilverGramPrice] = useState(0);
  const [goldGramPrice, setGoldGramPrice] = useState(0);
  const [totalGram, setTotalGram] = useState(0);
  let detailId = id[0];

  const fetchDetail = (detailId) => {
    dispatch(showLoading());
    APIS.getcurrentmarketprice("GOLD").then((res) =>
      setGoldGramPrice(res.data.data)
    );

    APIS.getcurrentmarketprice("SILVER").then((res) =>
      setSilverGramPrice(res.data.data)
    );

    APIS.getAppraisalWeightRates()
      .then(({ data: { data } }) => {
        setWeightRates(data.content);
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response.data.message }))
      );

    const bizRequestData = {
      bizServiceType: serviceType,
    };
    const api = isShop
      ? APIS.getAppraisalRequestDetail(detailId)
      : APIS.getPurchaseDetail(detailId, objToQueryStr(bizRequestData));
    api
      .then((res) => {
        setDetailData({
          ...res.data.data,
        });
        const totalGramSum =
          res.data.data.assetType === "SILVER"
            ? res.data.data.silverProduct.reduce(
                (accumulator, currentObject) => {
                  return (
                    Number(accumulator) +
                    Number(currentObject.appraisalWeightGram)
                  );
                },
                0
              )
            : res.data.data.goldProduct.reduce((accumulator, currentObject) => {
                return (
                  Number(accumulator) +
                  Number(currentObject.appraisalWeightGram)
                );
              }, 0);
        setTotalGram(totalGramSum);
      })
      .catch((err) =>
        dispatch(showAlert({ message: err.response?.data?.message }))
      )
      .finally(() => dispatch(hideLoading()));
  };

  useEffect(() => {
    if (detailId) {
      fetchDetail(detailId);
    }
  }, [detailId]);

  if (!detailData) {
    return null;
  }

  let tg =
    detailData?.status === "교환완료"
      ? detailData?.appraisalWeightGram
      : weightRates && weightRates.length
      ? detailData?.appraisalSimples?.reduce((a, b) => {
          return (
            b.weightGram *
              (weightRates?.filter(
                (y) =>
                  y.assetType === b.appraisalProduct.assetType &&
                  y.purity === b.purity
              )[0]?.weightRatePct /
                100) *
              (b.appraisalProduct.weightRatePct / 100) *
              b.quantity +
            a
          );
        }, 0.0)
      : 0;
  const tableData = {
    receptionStore: detailData?.shop?.name,
    receptionNumber: detailData?.number,
    requestDate: moment(
      detailData?.appraisalRequestStatus?.find((x) => x.status === "접수")
        ?.createdAt
    ).format("YYYY.MM.DD HH:mm"),
    memberName: detailData?.user?.name,
    memberId: detailData?.user?.id,
    receptionMethod: detailData?.method,
    progressStatus: detailData?.status,
    reservationDate:
      detailData?.method === "현장감정"
        ? "-"
        : detailData?.method === "택배"
        ? `${detailData?.collectDate || "-"} ${detailData?.collectTime || "-"}`
        : detailData?.visitDate,
    modifiedDate: moment(
      detailData?.appraisalRequestStatus?.[
        detailData?.appraisalRequestStatus?.length - 1
      ]?.updatedAt
    ).format("YYYY.MM.DD HH:mm"),
    phone: formatPhone(detailData?.user?.phone),
    bankAccount: detailData?.user?.bankAccount,
    bankName: detailData?.user?.bankName,
    deadlineStockDt: detailData.deadlineStockDt,
    collectAddress: detailData?.collectAddress || "",
    deliveryNo: detailData?.deliveryNo || "",
  };
  const detailDataAppraisalSimples = detailData?.appraisalSimples;

  return (
    <Wrap style={{ height: "97%" }}>
      <div style={{ height: "100%", marginTop: 10, width: "100%" }}>
        <Table>
          <colgroup>
            <col width="8%" />
            <col width="20%" />
            <col width="8%" />
            <col width="20%" />
          </colgroup>
          {isShop ? (
            <tbody>
              <tr>
                <Th>접수번호</Th>
                <Td>{tableData?.receptionNumber || "-"}</Td>
                <Th>요청일</Th>
                <Td>{tableData?.requestDate || "-"}</Td>
              </tr>
              <tr>
                <Th>수정일</Th>
                <Td>{tableData?.modifiedDate}</Td>
                <Th>회원번호</Th>
                <Td>{tableData?.memberId}</Td>
              </tr>
              <tr>
                <Th>회원명</Th>
                <Td>{tableData?.memberName || "-"}</Td>
                <Th>연락처</Th>
                <Td>{tableData?.phone || "-"}</Td>
              </tr>
              <tr>
                <Th>접수 방법</Th>
                <Td>{tableData?.receptionMethod}</Td>
                {["현장감정", "내방"].includes(detailData?.method) && (
                  <>
                    <Th>접수 대리점</Th>
                    <Td>{tableData?.receptionStore || "-"}</Td>
                  </>
                )}
              </tr>
              <tr>
                <Th>진행 상태</Th>
                <Td>
                  {tableData?.progressStatus || "-"} /{" "}
                  {`(입고 마감일: ${
                    tableData.deadlineStockDt
                      ? moment(tableData.deadlineStockDt).format("YYYY.MM.DD")
                      : "- "
                  })`}
                </Td>
                <Th>예약 일시</Th>
                <Td>{tableData?.reservationDate || "-"}</Td>
              </tr>
              <tr>
                <Th>배송지</Th>
                <Td>{tableData.collectAddress}</Td>
                <Th>송장번호</Th>
                <Td>{tableData?.deliveryNo}</Td>
              </tr>
              {detailData.method === "택배" &&
                (detailData.status === "반송신청" ||
                  detailData.status === "반송완료") && (
                  <>
                    <tr>
                      <Th>반송지</Th>
                      <Td>{detailData.returnAddress}</Td>
                      <Th>반송 송장번호</Th>
                      <Td>{detailData?.returnNo}</Td>
                    </tr>
                    <tr>
                      <Th>반송 예정일</Th>
                      <Td colSpan={3}>
                        {detailData?.returnDate &&
                          `${detailData?.returnDate}  ${detailData?.returnTime}`}
                      </Td>
                    </tr>
                  </>
                )}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <Th>접수번호</Th>
                <Td>{tableData?.receptionNumber || "-"}</Td>
                <Th>요청일</Th>
                <Td>{tableData?.requestDate || "-"}</Td>
              </tr>
              <tr>
                <Th>수정일</Th>
                <Td>{tableData?.modifiedDate}</Td>
                <Th>회원번호</Th>
                <Td>{tableData?.memberId}</Td>
              </tr>
              <tr>
                <Th>회원명</Th>
                <Td>{tableData?.memberName || "-"}</Td>
                <Th>연락처</Th>
                <Td>{tableData?.phone || "-"}</Td>
              </tr>
              <tr>
                <Th>접수 방법</Th>
                <Td>
                  {serviceType
                    ?.replace("BIZ_CONSIGNMENT", "위탁매입")
                    ?.replace("BIZ_DIRECT", "다이렉트")}
                </Td>
                <Th>접수 대리점</Th>
                <Td>{tableData?.receptionStore || "-"}</Td>
              </tr>
              <tr>
                <Th>진행 상태</Th>
                <Td>
                  {tableData?.progressStatus || "-"}
                  {serviceType !== "BIZ_CONSIGNMENT" &&
                    `/ (입고 마감일: ${
                      tableData.deadlineStockDt
                        ? moment(tableData.deadlineStockDt).format("YYYY.MM.DD")
                        : "- "
                    })`}
                </Td>
                <Th>은행명 / 계좌번호</Th>
                <Td>{`${tableData?.bankName || "-"} / ${
                  tableData?.bankAccount || "-"
                }`}</Td>
              </tr>
            </tbody>
          )}
        </Table>
        {isShop && detailData?.method !== "현장감정" && (
          <>
            <Flex row style={{ justifyContent: "space-between" }}>
              <span
                style={{
                  margin: "0px 0px",
                  marginTop: 30,
                  fontWeight: "bold",
                  fontFamily: "NotoSansKR-Bold",
                  fontSize: "16px",
                }}
              >
                회원 간편 감정 정보
              </span>
              <div
                style={{
                  margin: "30px 20px 0px 50px",
                  textAlign: "right",
                  fontWeight: "bold",
                  fontSize: 16,
                  fontFamily: "NotoSansKR-Bold",
                }}
              >
                간편 감정 결과 :
                {detailData?.appraisalSimples?.[0]?.appraisalProduct?.assetType}{" "}
                {parseFloat(tg).toFixed(2) +
                  "g = " +
                  numFormat(
                    tg *
                      (detailData?.appraisalSimples?.[0]?.appraisalProduct
                        ?.assetType === "GOLD"
                        ? goldGramPrice
                        : silverGramPrice)
                  ) +
                  "원"}
              </div>
            </Flex>
            <div
              style={{
                width: "100%",
              }}
            >
              {detailDataAppraisalSimples?.map((item, i) => {
                const detailDataAppraisalSimplesImage = [
                  { src: detailData?.appraisalSimples[i]?.topImageUrl },
                  { src: detailData?.appraisalSimples[i]?.frontImageUrl },
                  { src: detailData?.appraisalSimples[i]?.leftSideImageUrl },
                  { src: detailData?.appraisalSimples[i]?.rightSideImageUrl },
                ];
                return (
                  <Table>
                    <colgroup>
                      <col width="8%" />
                      <col width="20%" />
                      <col width="8%" />
                      <col width="20%" />
                    </colgroup>
                    <tbody>
                      <tr>
                        <Th>제품분류</Th>
                        <Td>
                          {item?.appraisalProduct?.assetType +
                            ">" +
                            item?.appraisalProduct?.name}
                        </Td>
                        <Th>순도</Th>
                        <Td>
                          {getPurityLabel(
                            item?.purity,
                            item?.appraisalProduct.assetType
                          )}
                        </Td>
                      </tr>
                      <tr>
                        <Th>중량</Th>
                        <Td>{parseFloat(item?.weightGram) + "g"}</Td>
                        <Th>수량</Th>
                        <Td>{numberWithCommas(item?.quantity) || "-"}</Td>
                      </tr>
                      <tr>
                        <Th>이미지</Th>
                        <Td colSpan={3}>
                          {detailDataAppraisalSimplesImage?.map((y, z) => {
                            return (
                              y?.src && (
                                <img
                                  onClick={() => {
                                    dispatch(
                                      showImageViewer({
                                        images: detailDataAppraisalSimplesImage
                                          ? detailDataAppraisalSimplesImage
                                          : "",
                                        activeIndex:
                                          detailDataAppraisalSimplesImage?.findIndex(
                                            (item) =>
                                              item.src ? item.src === y.src : 0
                                          ),
                                      })
                                    );
                                  }}
                                  key={z.toString()}
                                  alt="gurantter"
                                  src={consts.s3BaseUrl + "/" + y.src}
                                  style={{
                                    cursor: "pointer",
                                    height: "80px",
                                    marginRight: 16,
                                    "&:last-child": { marginRight: 0 },
                                  }}
                                />
                              )
                            );
                          })}
                        </Td>
                      </tr>
                      <tr>
                        <Th>보증서</Th>
                        <Td colSpan={3}>
                          {item?.guaranteeImageUrl ? (
                            <img
                              onClick={() => {
                                dispatch(
                                  showImageViewer({
                                    images: [
                                      {
                                        src: item?.guaranteeImageUrl,
                                      },
                                    ],
                                    activeIndex: 0,
                                  })
                                );
                              }}
                              alt="gurantter"
                              src={
                                consts.s3BaseUrl + "/" + item?.guaranteeImageUrl
                              }
                              style={{
                                height: "80px",
                                marginRight: 16,
                                "&:last-child": { marginRight: 0 },
                              }}
                            />
                          ) : (
                            ""
                          )}
                        </Td>
                      </tr>
                    </tbody>
                  </Table>
                );
              })}
            </div>
          </>
        )}
        <StatusUpdater1
          key={JSON.stringify(detailData)}
          fetchDetail={fetchDetail}
          fetchList={fetchList}
          list
          totalGramSum={totalGram}
          tableData={tableData}
          goldGramPrice={goldGramPrice}
          silverGramPrice={silverGramPrice}
          isShop={isShop}
          detailData={detailData}
          serviceType={serviceType}
          bizPurchasePrice={bizPurchasePrice}
          {...detailData}
        />
        {/* {detailData?.method === "현장감정" || detailData?.method === "내방" ? (
        ) : (
          <></>
        )} */}
      </div>
    </Wrap>
  );
};

export default AppraisalDetail;

//실물인출
const StatusUpdater1 = ({
  fetchDetail,
  fetchList,
  tableData,
  totalGramSum,
  appraisalRequestStatus,
  id,
  goldProduct,
  silverProduct,
  assetType,
  status,
  stockStatus,
  appraisalWeightGram,
  goldGramPrice,
  silverGramPrice,
  // 택배 방법
  method,
  isShop,
  detailData,
  serviceType,
  bizPurchasePrice,
}) => {
  const dispatch = useDispatch();
  let clickCheck = false;
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || {};
  const [t1, setT1] = useState(""); // 감정시작 - 반려 사유 입력
  const [t2, setT2] = useState(""); // 감정결과 - 사유
  const [t3, setT3] = useState(""); // 반송 - 사유
  const [t4, setT4] = useState(""); // 감정 결과 숫자
  // const [approvalGram, setApprovalGram] = useState(""); // 승인 그램 보여주는 칸
  const [approvalReason, setApprovalReason] = useState(""); //  승인 사유 보여주는 칸

  const [r1, setR1] = useState(""); // 감정시작 라운드 버튼
  const [r2, setR2] = useState(""); // 감정결과 라운드 버튼
  const [r3, setR3] = useState(""); // 반송 라운드 버튼
  const [approval, setApproval] = useState("1"); //승인 라운드 버튼
  const [selected, setSelected] = useState(
    assetType === "GOLD" ? 0 : assetType === "SILVER" ? 1 : 0
  );
  const [show, setShow] = useState(false);
  const [totalGram, setTotalGram] = useState(0);
  // const [appraiseGram, setAppraisalGram] = useState(""); //환산 중량
  const [goldProductList, setGoldProductList] = useState(goldProduct);
  const [silverProductList, setSilverProductList] = useState(silverProduct);
  const [postOrderNumber, setPostOrderNumber] = useState("");
  const printComponentRef = useRef(null);
  const [bizTradeSelectType, setBizTradeSelectType] = useState(""); // 주문 취소, 주문 요청, 출금 요청
  const [bizTransactionVisible, setBizTransactionVisible] = useState(false);
  const [tradeHistory, setTradeHistory] = useState([]);
  const tradeLogList = [
    serviceType === "BIZ_CONSIGNMENT"
      ? {
          total: "합계",
          appraisalWeightGram: detailData?.appraisalWeightGram, //매입중량
          userTradeKrw: detailData?.userTradeKrw, //고객지급금
          shopTradeFee: detailData?.shopTradeFee, //대리점 수수료
        }
      : {
          total: "합계",
          appraisalWeightGram: detailData?.appraisalWeightGram, // 주문중량
          contractWeightGram: detailData?.contractWeightGram, //체결중량
          waitOrderGram: detailData?.waitOrderGram, //주문대기
          waitContractGram: detailData?.waitContractGram, //체결대기
          waitOrderPrice: detailData?.waitOrderPrice, // 매도가격
          withdrawComplete: detailData?.withdrawComplete, //출금완료
          withdrawNone: detailData?.withdrawNone, //미출금
          withdrawConfirm: detailData?.withdrawConfirm, //출금 승인대기
        },
  ];

  useEffect(() => {
    if (!detailData?.userTradeLogList?.length) return;

    const updatedTradeHistory = detailData.userTradeLogList.map((item) => ({
      ...item,
      completedAt: item.completedAt
        ? formatTime(item.completedAt, "YYYY-MM-DD HH:mm:ss")
        : "-",
    }));

    // 합계 계산
    const totalData = updatedTradeHistory.reduce(
      (acc, item) => {
        acc.userFee += item.userFee;
        acc.tradeGram =
          Math.round((acc.tradeGram + item.tradeGram) * 1000) / 1000;
        acc.usingShopFee += item.usingShopFee;
        return acc;
      },
      {
        userFee: 0,
        tradeGram: 0,
        usingShopFee: 0,
      }
    );

    updatedTradeHistory.push({
      completedAt: "합계",
      tradeType: " ",
      orderType: " ",
      pureTradeKrw: " ", // 결제 총액
      userFee: totalData.userFee, // 합계 수수료
      orderAmount: " ", // 주문금액
      pricePerGram: " ",
      tradeGram: totalData.tradeGram, // 합계 중량
      usingShopFee: totalData.usingShopFee, // 대리점 수수료
    });

    // 숫자에 콤마 추가
    const formattedTradeHistory = updatedTradeHistory.map((item) => ({
      ...item,
      orderType: item.orderType ? item.orderType : "-",
      pureTradeKrw: item.pureTradeKrw
        ? numberWithCommas(item.pureTradeKrw)
        : "-",
      userFee: item.userFee ? numberWithCommas(item.userFee) : "-",
      orderAmount: item.orderAmount ? numberWithCommas(item.orderAmount) : "-",
      pricePerGram: item.pricePerGram
        ? numberWithCommas(item.pricePerGram)
        : "-",
      tradeGram: item.tradeGram ? numberWithCommas(item.tradeGram) : "-",
      usingShopFee: item.usingShopFee
        ? numberWithCommas(item.usingShopFee)
        : "-",
    }));

    // 상태 업데이트
    setTradeHistory(formattedTradeHistory);
  }, [detailData]);

  useEffect(() => {
    setTotalGram(0);
    if (selected === 0) {
      setGoldProductList(goldProduct);
    } else {
      setSilverProductList(silverProduct);
    }
  }, [selected]);

  const parseNumericValue = (value) => {
    const cleaned = String(value).replace(/,/g, "").trim();
    if (cleaned === "") {
      return null;
    }
    return Number(cleaned);
  };

  //환산중량 절삭하는 함수
  const changeAppraisalWeightGram = (num) => {
    let stringAppraisalWeightGram = String(num);
    if (stringAppraisalWeightGram.includes(".")) {
      const numberDigits = stringAppraisalWeightGram.split(".")[1].length;
      if (
        (selected === 0 && numberDigits === 3) ||
        (selected === 1 && numberDigits === 1)
      ) {
        const lastNumber =
          stringAppraisalWeightGram[stringAppraisalWeightGram.length - 1];
        if (0 <= parseInt(lastNumber) && parseInt(lastNumber) <= 4) {
          stringAppraisalWeightGram = stringAppraisalWeightGram.replaceAt(
            stringAppraisalWeightGram.length - 1,
            "0"
          );
        } else if (5 <= parseInt(lastNumber) && parseInt(lastNumber) <= 9) {
          stringAppraisalWeightGram = stringAppraisalWeightGram.replaceAt(
            stringAppraisalWeightGram.length - 1,
            "5"
          );
        }
      }
    }
    return stringAppraisalWeightGram;
  };

  const handleSaveInventory = () => {
    setShow(!show);
  };

  const handleCancelAppraisal = () => {
    dispatch(showLoading());
    APIS.putAppraisalRequestStatus(id, {
      appraiseCancelReason: t1,
      status: "VERIFY_DENY",
    })
      .then((res) => {
        res.data.success &&
          dispatch(
            showAlert({
              message: "감정반려 되었습니다.",
              onClick: () => {
                fetchDetail(id);
                fetchList();
              },
            })
          );
      })
      .finally(() => dispatch(hideLoading()));
  };

  const avoidDuplicateClicks = (func) => {
    if (!clickCheck) {
      clickCheck = true;
      func();
    }
  };

  const cellRender = (cell, props) => {
    const navigationAttributes = useTableKeyboardNavigation(props.id);
    if (
      props.field === "createdAt" ||
      props.field === "status" ||
      props.field === "managerName" ||
      props.field === "assetType" ||
      props.field === "purityTypeName" ||
      props.field === "name"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "-webkit-center" }}
        >
          {props.dataItem[props.field]}
        </td>
      );
    } else if (
      props.field === "appraiseGram" ||
      props.field === "basicPct" ||
      props.field === "productWeightGram" ||
      props.field === "appraisalWeightGram" ||
      props.field === "shopTradeFee" ||
      props.field === "userTradeKrw" ||
      props.field === "contractWeightGram" ||
      props.field === "waitOrderGram" ||
      props.field === "waitContractGram" ||
      props.field === "waitOrderPrice" ||
      props.field === "withdrawComplete" ||
      props.field === "withdrawNone" ||
      props.field === "withdrawConfirm"
    ) {
      return (
        <td
          colSpan={props.colSpan}
          role={"gridcell"}
          aria-colindex={props.ariaColumnIndex}
          aria-selected={props.isSelected}
          {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
          }}
          {...navigationAttributes}
          style={{ textAlign: "right" }}
        >
          {props.dataItem[props.field]
            ? numberWithCommas(props.dataItem[props.field])
            : "-"}
        </td>
      );
    }
    return cell;
  };

  const onSave = async () => {
    if (["접수", "배송중", "감정시작", "감정반려"].includes(status)) {
      if ([0, 1].includes(selected) && status === "감정시작") {
        if (totalGram === "" || totalGram === 0) {
          clickCheck = false;
          return dispatch(
            showAlert({ message: "올바른 감정결과 중량을 입력해 주세요." })
          );
        }
        return APIS.putAppraisalRequestStatus(id, {
          appraiseGram: parseFloat(totalGram),
          appraiseMemo: t2,
          assetType: selected === 0 ? "GOLD" : "SILVER",
          status: "VERIFY_COMPLETE",
          productList:
            selected === 0
              ? goldProductList
                  .filter(
                    (item) =>
                      item.appraisalWeightGram !== null &&
                      item.productWeightGram !== null &&
                      item.productWeightGram !== "0" &&
                      item.basicPct !== "0"
                  )
                  .map((item) => {
                    return {
                      ...item,
                      appraisalWeightGram: parseNumericValue(
                        item.appraisalWeightGram
                      ),
                      productWeightGram: parseNumericValue(
                        item.productWeightGram
                      ),
                      basicPct: parseNumericValue(item.basicPct),
                    };
                  })
              : silverProductList
                  .filter(
                    (item) =>
                      item.appraisalWeightGram !== null &&
                      item.productWeightGram !== null &&
                      item.productWeightGram !== "0" &&
                      item.basicPct !== "0"
                  )
                  .map((item) => {
                    return {
                      ...item,
                      appraisalWeightGram: parseNumericValue(
                        item.appraisalWeightGram
                      ),
                      productWeightGram: parseNumericValue(
                        item.productWeightGram
                      ),
                      basicPct: parseNumericValue(item.basicPct),
                    };
                  }),
        })
          .then((res) => {
            if (res.data.success) {
              navigate(location.pathname, {
                replace: true,
                state: { appraisal: { ...location.state?.appraisal } },
              });
              dispatch(
                showAlert({
                  message: "감정 완료 되었습니다.",
                  onClick: () => {
                    fetchDetail(id); //디테일 정보조회
                    fetchList();
                  },
                })
              );
            } else {
              clickCheck = false;
              dispatch(showAlert({ message: res.data.message }));
            }
          })
          .catch((err) => {
            clickCheck = false;
            dispatch(showAlert({ message: err.response.data.message }));
          });
      }
      if (status === "접수" && method === "택배") {
        return APIS.putAppraisalRequestStatus(id, {
          status: "DELIVERY",
          postOrderNumber: postOrderNumber,
        })
          .then((res) => {
            if (res.data.success) {
              navigate(location.pathname, {
                replace: true,
                state: { appraisal: { ...location.state?.appraisal } },
              });
              dispatch(
                showAlert({
                  message: "배송중 상태로 변경되었습니다.",
                  onClick: () => {
                    fetchDetail(id); // 디테일 정보 조회
                    fetchList();
                  },
                })
              );
            } else {
              clickCheck = false;
              dispatch(showAlert({ message: res.data.message }));
            }
          })
          .catch((err) => {
            clickCheck = false;
            dispatch(showAlert({ message: err.response.data.message }));
          });
      }
      if (!r1 && status === "접수") {
        clickCheck = false;
        return dispatch(
          showAlert({ message: "감정시작 상태를 선택해 주세요." })
        );
      } else if (r1 === "2" && !t1) {
        clickCheck = false;
        return dispatch(
          showAlert({ message: "감정반려 사유를 입력해 주세요." })
        );
      }
      return APIS.putAppraisalRequestStatus(
        id,
        r1 === "1"
          ? {
              status: "VERIFY_START",
              appraiseCancelReason: "",
            }
          : {
              status: "VERIFY_DENY",
              appraiseCancelReason: t1,
            }
      )
        .then((res) => {
          if (!res.data.success) {
            clickCheck = false;
            dispatch(showAlert({ message: res.data.message }));
          } else {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });

            return dispatch(
              showAlert({
                message:
                  r1 === "1"
                    ? "감정이 시작 되었습니다."
                    : "감정반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          }
        })
        .catch((err) => {
          clickCheck = false;
          dispatch(showAlert({ message: err.response.data.message }));
        });
    } else if (
      ["감정완료", "반송신청", "반송반려", "교환완료"].includes(status)
    ) {
      if (!r3) {
        clickCheck = false;
        return dispatch(showAlert({ message: "반송 상태를 선택해 주세요." }));
      }
      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? status === "교환완료"
            ? {
                status:
                  status === "교환완료" ? "RETURN_WAIT" : "RETURN_COMPLETE",
                returnCancelReason: status === "교환완료" ? t3 : "",
                appraiseGram:
                  status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).appraiseGram
                    : "",
                assetType:
                  status === "교환완료"
                    ? appraisalRequestStatus.find(
                        (item) => item.status === "감정완료"
                      ).assetType
                    : "",
              }
            : { status: "RETURN_COMPLETE", returnCancelReason: "" }
          : {
              status: "RETURN_DENY",
              returnCancelReason: t3,
            }
      )
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });

            return dispatch(
              showAlert({
                message:
                  r3 === "1"
                    ? status === "교환완료"
                      ? "반송 대기 처리 되었습니다."
                      : "반송 완료 처리 되었습니다."
                    : "반송반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          } else {
            clickCheck = false;
            dispatch(showAlert({ message: res.data.message }));
          }
        })
        .catch((err) => {
          clickCheck = false;
          dispatch(showAlert({ message: err.response.data.message }));
        });
    } else if (["승인대기"].includes(status)) {
      if (!r3) {
        clickCheck = false;
        return dispatch(showAlert({ message: "반송 상태를 선택해 주세요." }));
      } else if (r3 === "2" && !t3) {
        clickCheck = false;
        return dispatch(
          showAlert({ message: "반송반려 사유를 입력해 주세요." })
        );
      }
    }
  };
  const onSaveAdmin = async (adminPassword) => {
    let last = appraisalRequestStatus[appraisalRequestStatus.length - 1];
    if (["승인대기", "승인완료", "승인반려"].includes(status)) {
      if (!approval) {
        return dispatch(
          showAlert({ message: "승인대기 상태를 선택해 주세요." })
        );
      } else if (approval === "1" && !approvalReason) {
        return dispatch(showAlert({ message: "승인 사유를 입력해 주세요." }));
      } else if (approval === "2" && !t3) {
        return dispatch(
          showAlert({ message: "승인 반려 사유를 입력해 주세요." })
        );
      }

      return APIS.putAppraisalRequestStatus(
        id,
        approval === "1"
          ? {
              status: "VERIFY_COMPLETE",
              appraiseMemo: approvalReason,
              appraiseGram: last.appraiseGram,
              assetType: last.assetType,
              password: adminPassword,
            }
          : {
              status: "CONFIRM_DENY",
              returnCancelReason: t3,
              password: adminPassword,
            }
      )
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });
            dispatch(
              showAlert({
                message:
                  approval === "1"
                    ? "승인완료 처리 되었습니다."
                    : "승인반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          } else {
            dispatch(showAlert({ message: res.data.message }));
          }
        })
        .catch((err) => {
          dispatch(showAlert({ message: err.response.data.message }));
        });
    } else if (["감정완료", "반송신청", "반송반려"].includes(status)) {
      if (!r3) {
        return dispatch(showAlert({ message: "반송 상태를 선택해 주세요." }));
      } else if (r3 === "2" && !t3) {
        return dispatch(
          showAlert({ message: "반송반려 사유를 입력해 주세요." })
        );
      }
      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? {
              status: "RETURN_COMPLETE",
              returnCancelReason: "",
              password: adminPassword,
            }
          : {
              status: "RETURN_DENY",
              returnCancelReason: t3,
              password: adminPassword,
            }
      )
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });
            dispatch(
              showAlert({
                message:
                  r3 === "1"
                    ? "반송 완료 처리 되었습니다."
                    : "반송반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          } else {
            dispatch(showAlert({ message: res.data.message }));
          }
        })
        .catch((err) => {
          dispatch(showAlert({ message: err.response.data.message }));
        });
    } else if (["반송대기"].includes(status)) {
      if (!r3) {
        return dispatch(showAlert({ message: "반송 상태를 선택해 주세요." }));
      } else if (r3 === "2" && !t3) {
        return dispatch(
          showAlert({ message: "반송반려 사유를 입력해 주세요." })
        );
      }

      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? {
              status: "RETURN_COMPLETE",
              returnCancelReason: "",
              password: adminPassword,
            }
          : {
              status: "RETURN_DENY",
              returnCancelReason: t3,
              password: adminPassword,
            }
      )
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });
            dispatch(
              showAlert({
                message:
                  r3 === "1"
                    ? "반송 완료 처리 되었습니다."
                    : "반송반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          } else {
            dispatch(showAlert({ message: res.data.message }));
          }
        })
        .catch((err) => {
          dispatch(showAlert({ message: err.response.data.message }));
        });
    } else if (["교환완료"].includes(status) || stockStatus === "DELIVERY") {
      if (!r3) {
        return dispatch(showAlert({ message: "반송 상태를 선택해 주세요." }));
      } else if (r3 === "1" && !t3) {
        return dispatch(
          showAlert({ message: "반송요청 사유를 입력해 주세요." })
        );
      }

      return APIS.putAppraisalRequestStatus(
        id,
        r3 === "1"
          ? {
              status:
                status === "교환완료" || stockStatus === "DELIVERY"
                  ? "RETURN_WAIT"
                  : "RETURN_COMPLETE",
              returnCancelReason:
                status === "교환완료" || stockStatus === "DELIVERY" ? t3 : "",
              appraiseGram:
                status === "교환완료" || stockStatus === "DELIVERY"
                  ? appraisalRequestStatus.find(
                      (item) => item.status === "감정완료"
                    ).appraiseGram
                  : "",
              assetType:
                status === "교환완료" || stockStatus === "DELIVERY"
                  ? appraisalRequestStatus.find(
                      (item) => item.status === "감정완료"
                    ).assetType
                  : "",
              password: adminPassword,
            }
          : null
      )
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });
            dispatch(
              showAlert({
                message:
                  r3 === "1"
                    ? "반송 요청 처리 되었습니다."
                    : "반송반려 되었습니다.",
                onClick: () => {
                  fetchDetail(id); //디테일 정보조회
                  fetchList();
                },
              })
            );
          } else {
            dispatch(showAlert({ message: res.data.message }));
          }
        })
        .catch((err) => {
          dispatch(showAlert({ message: err.response.data.message }));
        });
    }
  };

  const handlePrint = useReactToPrint({
    content: () => printComponentRef.current,
  });

  const handleClickPrint = () => {
    if (status === "교환완료" && stockStatus === "WAIT") {
      return APIS.patchAppraisalPrintStockDelivery(id)
        .then((res) => {
          if (res.data.success) {
            navigate(location.pathname, {
              replace: true,
              state: { appraisal: { ...location.state?.appraisal } },
            });
            fetchDetail(id);
            fetchList();
            handlePrint();
          } else {
            dispatch(
              showAlert({
                message: res.data.message,
                onClick: () => {
                  dispatch(hideAlert());
                },
              })
            );
          }
        })
        .catch((err) => {
          dispatch(showAlert({ message: err.response.data.message }));
        });
    }
    handlePrint();
    handleSaveInventory();
  };

  const reformData = (data) => {
    const copyData = [...data] || [];
    const parseData = [];
    copyData?.map((item, index) => {
      parseData?.push({
        createdAt: formatTime(item.createdAt, "YYYY-MM-DD HH:mm:ss") || "-",
        status: item.status || "-",
        admin: item.admin || "-",
        assetType: item.assetType || "-",
        appraiseGram: item.appraiseGram || "-",
        appraiseMemo:
          appraisalRequestStatus[index].status === "감정반려"
            ? item.appraiseCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정완료"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "반송반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "승인대기"
            ? item.appraiseMemo || "-"
            : appraisalRequestStatus[index].status === "승인반려"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "반송대기"
            ? item.returnCancelReason || "-"
            : appraisalRequestStatus[index].status === "감정시작"
            ? item.returnCancelReason || "-"
            : item.appraiseMemo || "-",
        managerName: item.managerName || "-",
        stockStatus: item.stockStatus || "-",
      });
    });
    return parseData;
  };

  const parseData = appraisalRequestStatus
    ? reformData(appraisalRequestStatus)
    : [];

  const NumberInputCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const rawValue =
      dataItem[field] !== undefined && dataItem[field] !== null
        ? dataItem[field].toString()
        : "";

    const onChangeValue = (e) => {
      let inputStr = e.target.value;
      const raw = inputStr.replace(/,/g, "");
      const regex = /^(\d+(\.\d{0,3})?)?$/;
      if (!regex.test(raw)) {
        return;
      }
      const formatted = raw === "" ? "" : numberWithCommas(raw);
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: formatted,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          <KendoInput
            type="text"
            value={rawValue}
            onChange={onChangeValue}
            id={`productWeightGram-${dataIndex}`}
            name={"productWeightGram"}
            style={{
              width: 100,
              border: "1px solid rgba(66,66,66,0.5)",
              margin: "2px 0px",
            }}
          />
        </tr>
      </td>
    );
  }, []);

  const basicPctCell = useCallback((props) => {
    const { dataIndex, dataItem, field } = props;
    const rawValue =
      dataItem[field] !== undefined && dataItem[field] !== null
        ? dataItem[field].toString()
        : "";

    const onChangeValue = (e) => {
      let inputStr = e.target.value;
      const raw = inputStr.replace(/,/g, "");
      const regex = /^(\d+(\.\d{0,3})?)?$/;
      if (!regex.test(raw)) {
        return;
      }
      const formatted = raw === "" ? "" : numberWithCommas(raw);
      if (!!props.onChange) {
        props.onChange({
          dataIndex,
          value: formatted,
          field,
          syntheticEvent: e.syntheticEvent,
          dataItem,
        });
      }
    };

    const list =
      dataItem.assetType === "GOLD" ? goldProductList : silverProductList;
    return (
      <td style={{ textAlign: "-webkit-center" }}>
        <tr>
          {status === "감정시작" &&
          list?.[dataIndex]?.isWeightRateChange === true ? (
            <div style={{ display: "flex" }}>
              <KendoInput
                type="text"
                value={rawValue}
                onChange={onChangeValue}
                id={`basicPct-${dataIndex}`}
                name={"basicPct"}
                style={{
                  width: 100,
                  border: "1px solid rgba(66,66,66,0.5)",
                  margin: "2px 0px",
                }}
              />
              <div
                style={{
                  alignSelf: "center",
                  fontSize: "medium",
                }}
              >
                %
              </div>
            </div>
          ) : list?.[dataIndex]?.basicPct ? (
            `${numberWithCommas(list?.[dataIndex]?.basicPct)}%`
          ) : (
            "-"
          )}
        </tr>
      </td>
    );
  }, []);

  const onItemChange = useCallback(
    (props) => {
      const { value, dataItem, field } = props;
      let copyData;
      if (dataItem.assetType === "SILVER") {
        copyData = cloneDeep(silverProductList);
      } else {
        copyData = cloneDeep(goldProductList);
      }
      let newCopy = copyData.map((item) => {
        const basicPctValue = field === "basicPct" ? value : item.basicPct;
        const productWeightGramValue =
          field === "productWeightGram" ? value : item.productWeightGram;
        if (item.id === dataItem.id) {
          const cleanProductWeightGramValue = parseNumericValue(
            productWeightGramValue
          );
          const cleanBasicPctValue = parseNumericValue(basicPctValue);
          return {
            ...item,
            basicPct: basicPctValue,
            productWeightGram: productWeightGramValue,
            appraisalWeightGram:
              cleanProductWeightGramValue === null
                ? null
                : dataItem.assetType === "GOLD"
                ? changeAppraisalWeightGram(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (cleanBasicPctValue / 100) *
                            cleanProductWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 10
                    ) / 1000
                  )
                : changeAppraisalWeightGram(
                    Math.floor(
                      Math.round(
                        Number(
                          (
                            (cleanBasicPctValue / 100) *
                            cleanProductWeightGramValue
                          ).toFixed(13)
                        ) * 10000
                      ) / 1000
                    ) / 10
                  ),
          };
        } else {
          return item;
        }
      });

      //환산중량 총 합계
      const appraisalWeightGramSum = newCopy.reduce(
        (accumulator, currentObject) => {
          return Number(
            (
              Number(accumulator) + Number(currentObject.appraisalWeightGram)
            ).toFixed(13)
          );
        },
        0
      );
      setTotalGram(appraisalWeightGramSum);
      if (dataItem.assetType === "SILVER") {
        setSilverProductList(newCopy);
      } else {
        setGoldProductList(newCopy);
      }
    },
    [
      setGoldProductList,
      setSilverProductList,
      goldProductList,
      silverProductList,
    ]
  );

  // 감정시작 UI 노출 조건
  const AppraisalStartCondition = () => {
    // 택배
    if (method === "택배") {
      return status === "배송중";
    } else {
      // 내방, 현장 감정
      return status === "접수";
    }
  };

  const handleOpenPopup = (type) => {
    setBizTransactionVisible(!bizTransactionVisible);
    setBizTradeSelectType(type);
  };

  return (
    <>
      <Flex
        style={{
          flexDirection: "row",
          width: "100%",
          alignItems: "center",
          marginTop: 10,
        }}
      >
        <div
          style={{
            paddingRight: 20,
            display: "flex",
          }}
        >
          {isShop && appraisalRequestStatus?.length > 0 ? (
            AppraisalStartCondition() ? (
              <>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    borderRight: "1px solid rgb(224,224,224)",
                    borderBottom: "1px solid rgb(224,224,224)",
                    borderLeft: "1px solid rgb(224,224,224)",
                    borderTop: "1px solid rgb(224,224,224)",
                    padding: 14,
                    fontSize: 16,
                    fontFamily: "NotoSansKR-Bold",
                  }}
                >
                  감정시작
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 16,
                    "& svg": {
                      color: "#000",
                    },
                    "& .MuiFormControlLabel-root": {
                      minWidth: 150,
                    },
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  <RadioGroup
                    data={[
                      { label: "감정시작", value: "1" },
                      { label: "감정반려", value: "2" },
                    ]}
                    layout={"horizontal"}
                    disabled={
                      appraisalRequestStatus.filter(
                        (x) => x.status === "감정완료"
                      )[0]
                    }
                    onChange={(e) => {
                      setR1(e.value);
                    }}
                  />
                  <Input
                    value={t1}
                    disabled={r1 === "1"}
                    type="text"
                    autoComplete="off"
                    onChange={(e) => {
                      setT1(e.target.value);
                    }}
                    style={{
                      fontFamily: "NotoSansKR-Bold",
                      fontSize: "16px",
                      marginLeft: 20,
                    }}
                    placeholder="반려 사유 입력"
                  />
                </div>
              </>
            ) : status === "감정시작" ? (
              <>
                <span
                  style={{
                    margin: "0px 0px",
                    marginTop: 10,
                    fontWeight: "bold",
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  감정결과
                </span>
              </>
            ) : status === "감정완료" ? (
              <></>
            ) : status === "반송신청" ? (
              <Flex style={{ flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    borderRight: "1px solid rgb(224,224,224)",
                    borderBottom: "1px solid rgb(224,224,224)",
                    borderLeft: "1px solid rgb(224,224,224)",
                    borderTop: "1px solid rgb(224,224,224)",
                    padding: 14,
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                  }}
                >
                  반송(돌려받기)
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    paddingLeft: 16,
                    fontFamily: "NotoSansKR-Bold",
                    fontSize: "16px",
                    "& svg": {
                      color: "#000",
                    },
                    "& .MuiFormControlLabel-root": {
                      minWidth: 150,
                    },
                  }}
                >
                  <RadioGroup
                    data={[
                      { label: "반송완료", value: "1" },
                      // { label: "반송반려", value: "2" },
                    ]}
                    layout={"horizontal"}
                    disabled={
                      !(
                        (appraisalRequestStatus.filter(
                          (x) => x.status === "반송신청"
                        )?.length > 0 ||
                          appraisalRequestStatus.filter(
                            (x) => x.status === "감정완료"
                          )?.length > 0) &&
                        appraisalRequestStatus.filter(
                          (x) => x.status === "반송완료"
                        )?.length === 0
                      )
                    }
                    onChange={(e) => {
                      setT3("");
                      setR3(e.value);
                    }}
                  />
                </div>
              </Flex>
            ) : (status === "교환완료" && stockStatus === "WAIT") ||
              (status === "교환완료" && stockStatus === "DELIVERY") ? (
              <Flex style={{ flexDirection: "row" }}>
                <div
                  style={{
                    backgroundColor: "#f5f5f5",
                    textAlign: "center",
                    borderRight: "1px solid rgb(224,224,224)",
                    borderBottom: "1px solid rgb(224,224,224)",
                    borderLeft: "1px solid rgb(224,224,224)",
                    borderTop: "1px solid rgb(224,224,224)",
                    padding: 14,
                  }}
                >
                  반송(돌려받기)
                </div>
                <div style={{ marginLeft: 15 }}>
                  <RadioButton
                    value="1"
                    label="반송요청"
                    onChange={(e) => {
                      setT3("");
                      setR3(e.value);
                    }}
                  />
                  <Input
                    type="text"
                    autoComplete="nope"
                    disabled={
                      !(
                        appraisalRequestStatus?.filter(
                          (x) => x.status === "감정완료"
                        )?.length > 0 && r3 === "1"
                      )
                    }
                    value={t3}
                    onChange={(e) => {
                      setT3(e.target.value);
                    }}
                    placeholder="반송 요청 사유 입력"
                    style={{
                      flex: 1,
                      marginLeft: 20,
                      marginRight: 20,
                      paddingLeft: 16,
                      fontFamily: "NotoSansKR-Bold",
                      fontSize: 16,
                      height: "100%",
                      border: "1px solid rgb(224,224,224)",
                    }}
                  />
                  <Input
                    style={{
                      position: "absolute",
                      opacity: 0,
                      width: 0,
                      height: 0,
                      zIndex: -10,
                    }}
                    type="text"
                    value=""
                    autoComplete="on"
                  />
                </div>
              </Flex>
            ) : (
              <></>
            )
          ) : (
            ""
          )}
        </div>
        {isShop && (
          <Flex style={{ flexDirection: "row" }}>
            {status === "승인대기" ||
            status === "반송대기" ||
            status === "감정반려" ||
            status === "반송반려" ||
            status === "반송완료" ||
            status === "감정완료" ||
            status === "감정시작" ||
            status === "접수취소" ||
            (status === null && stockStatus !== "DELIVERY") ? (
              <></>
            ) : status === "교환완료" ? (
              stockStatus === "WAIT" || stockStatus === "DELIVERY" ? (
                <>
                  <AdminConfirmButton
                    title="감정평가"
                    callback={onSaveAdmin}
                    style={{
                      fontSize: 16,
                      height: "100%",
                      fontFamily: "NotoSansKR-Bold",
                      fontWeight: "bold",
                    }}
                  />
                </>
              ) : (
                <></>
              )
            ) : (
              <>
                {method === "택배" && status === "접수" && (
                  <div>
                    <span
                      style={{
                        margin: "0px 0px",
                        marginTop: 30,
                        fontWeight: "bold",
                        fontFamily: "NotoSansKR-Bold",
                        fontSize: "16px",
                      }}
                    >
                      송장번호
                    </span>
                    <Input
                      type="text"
                      autoComplete="nope"
                      value={postOrderNumber}
                      onChange={(e) => {
                        setPostOrderNumber(e.target.value);
                      }}
                      placeholder="송장번호 입력"
                      style={{
                        flex: 1,
                        marginLeft: 20,
                        marginRight: 20,
                        paddingLeft: 16,
                        fontFamily: "NotoSansKR-Bold",
                        fontSize: 16,
                        height: 40,
                        border: "1px solid rgb(224,224,224)",
                      }}
                    />
                  </div>
                )}
                <Button
                  style={{
                    backgroundColor: "black",
                    color: "white",
                    height: 38,
                    alignItems: "center",
                    fontSize: 16,
                    fontFamily: "NotoSansKR-Bold",
                    fontWeight: "bold",
                  }}
                  onClick={() => {
                    avoidDuplicateClicks(onSave);
                  }}
                >
                  저장
                </Button>
              </>
            )}
          </Flex>
        )}
      </Flex>
      {status === "접수" ? (
        <></>
      ) : (
        <>
          <div style={{ display: "flex", marginTop: 10, alignItems: "center" }}>
            <span
              style={{
                fontWeight: "bold",
                marginRight: 10,
              }}
            >
              자산타입
            </span>
            <RadioButton
              style={{
                color: "black",
              }}
              value={0}
              disabled={
                assetType === "GOLD" || assetType === null ? false : true
              }
              label="금"
              checked={selected === 0}
              onChange={(e) => {
                setSelected(e.value);
              }}
            />
            <RadioButton
              style={{
                color: "black",
                marginLeft: 10,
              }}
              value={1}
              disabled={
                assetType === "SILVER" || assetType === null ? false : true
              }
              label="은"
              checked={selected === 1}
              onChange={(e) => {
                setSelected(e.value);
              }}
            />
          </div>
          {selected === 0 ? (
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    marginRight: 20,
                  }}
                >
                  금 환산 중량 총 합계:
                  {`${
                    appraisalWeightGram
                      ? numberWithCommas(appraisalWeightGram)
                      : totalGram
                      ? numberWithCommas(totalGram)
                      : numberWithCommas(totalGramSum)
                  } 
                        g  
                      ${
                        status !== "접수" &&
                        status !== "감정시작" &&
                        status !== "감정반려"
                          ? `= 
                        ${numFormat(
                          appraisalRequestStatus?.find(
                            (item) => item.status === "감정완료"
                          )?.appraiseGram
                            ? appraisalRequestStatus?.find(
                                (item) => item.status === "감정완료"
                              )?.appraiseGram *
                                (appraisalRequestStatus?.find(
                                  (item) => item.status === "감정완료"
                                )?.assetType === "GOLD"
                                  ? goldGramPrice
                                  : silverGramPrice)
                            : appraisalRequestStatus?.find(
                                (item) => item.status === "승인대기"
                              )?.appraiseGram *
                                (appraisalRequestStatus?.find(
                                  (item) => item.status === "승인대기"
                                )?.assetType === "GOLD"
                                  ? goldGramPrice
                                  : silverGramPrice)
                        )}
                        원`
                          : ""
                      }`}
                </div>
                {status === "감정시작" ? (
                  <div>
                    <InputWrap>
                      <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={handleSaveInventory}
                      >
                        저장
                      </button>
                      <KendoInput
                        value={t1}
                        type="text"
                        autoComplete="off"
                        onChange={(e) => {
                          setT1(e.target.value);
                        }}
                        style={{ marginLeft: "5px" }}
                        placeholder="반려 사유 입력"
                      />
                      <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={handleCancelAppraisal}
                      >
                        감정취소
                      </button>
                    </InputWrap>
                    {show && (
                      <Dialog
                        style={{ zIndex: 200 }}
                        title={"계산표 등록"}
                        onClose={handleSaveInventory}
                      >
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <Grid
                              scrollable={"none"}
                              data={goldProductList.filter(
                                (item) =>
                                  item.appraisalWeightGram !== null &&
                                  item.productWeightGram !== null &&
                                  item.productWeightGram !== "0" &&
                                  item.basicPct !== "0"
                              )}
                              cellRender={cellRender}
                            >
                              <GridColumn
                                field="purityTypeName"
                                title="구분"
                                width={130}
                              />
                              <GridColumn
                                field="name"
                                title="품목"
                                width={130}
                              />
                              <GridColumn
                                field="appraisalWeightGram"
                                title="환산중량"
                                width={130}
                              />
                            </Grid>
                            <div
                              style={{
                                marginTop: 10,
                                fontSize: 16,
                                fontFamily: "NotoSansKR-Bold",
                                fontWeight: "bold",
                              }}
                            >
                              교환완료 중량 : {numberWithCommas(totalGram)}g
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <input
                                style={{
                                  width: "100%",
                                  height: 35,
                                  borderRadius: "0.2rem",
                                  fontFamily: "NotoSansKR-Bold",
                                  fontSize: "16px",
                                  border: "1px solid rgb(224,224,224)",
                                }}
                                value={t2}
                                type="text"
                                autoComplete="off"
                                onChange={(e) => {
                                  setT2(e.target.value);
                                }}
                                placeholder="감정내용 설명사항 입력(선택)"
                                disabled={status !== "감정시작"}
                              />
                            </div>
                          </div>
                        </p>
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleSaveInventory}
                          >
                            취소
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={() => {
                              avoidDuplicateClicks(onSave);
                            }}
                          >
                            확인
                          </button>
                        </DialogActionsBar>
                      </Dialog>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Grid
                style={{
                  width: "100%",
                  marginTop: 5,
                }}
                scrollable={"none"}
                data={goldProductList}
                cellRender={cellRender}
                onItemChange={onItemChange}
              >
                <GridColumn field="purityTypeName" title="구분" width={130} />
                <GridColumn field="name" title="품목" width={80} />
                <GridColumn
                  field="basicPct"
                  title="기준비율"
                  cell={basicPctCell}
                  width={120}
                />
                {status === "감정시작" ? (
                  <GridColumn
                    field="productWeightGram"
                    title="측정 실중량(g)"
                    cell={NumberInputCell}
                  />
                ) : (
                  <GridColumn
                    field="productWeightGram"
                    title="측정 실중량(g)"
                    width={60}
                  />
                )}
                <GridColumn
                  field="appraisalWeightGram"
                  title="환산 중량(g)"
                  cell={(props) => {
                    const { dataIndex } = props;
                    return (
                      <td style={{ width: 80, textAlign: "right" }}>
                        {goldProductList?.[dataIndex]?.appraisalWeightGram !==
                          null &&
                        goldProductList?.[dataIndex]?.productWeightGram !==
                          "" &&
                        goldProductList?.[dataIndex]?.basicPct !== "" &&
                        goldProductList?.[dataIndex]?.productWeightGram !==
                          null &&
                        goldProductList?.[dataIndex]?.productWeightGram !== "-"
                          ? numberWithCommas(
                              goldProductList?.[dataIndex]?.appraisalWeightGram
                            )
                          : "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="inspectProductWeightGram"
                  title="검품 측정 실중량(g)"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 60, textAlign: "right" }}>
                        {dataItem.inspectProductWeightGram || "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="inspectWeightGram"
                  title="검품 환산 중량(g)"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 60, textAlign: "right" }}>
                        {dataItem.inspectWeightGram || "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="status"
                  title="검품 상태"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 80, textAlign: "center" }}>
                        {dataItem.status
                          ?.replace("WAIT", "대기")
                          ?.replace("CANCEL", "취소")
                          ?.replace("COMPLETE", "완료") || "-"}
                      </td>
                    );
                  }}
                />
              </Grid>
            </div>
          ) : (
            <div style={{ width: "100%", overflowX: "auto" }}>
              <div style={{ display: "flex" }}>
                <div
                  style={{
                    fontWeight: "bold",
                    alignSelf: "center",
                    marginRight: 20,
                  }}
                >
                  은 환산 중량 총 합계:
                  {`${
                    appraisalWeightGram
                      ? numberWithCommas(appraisalWeightGram)
                      : totalGram
                      ? numberWithCommas(totalGram)
                      : numberWithCommas(totalGramSum)
                  } 
                          g  
                        ${
                          status !== "접수" &&
                          status !== "감정시작" &&
                          status !== "감정반려"
                            ? `= 
                          ${numFormat(
                            appraisalRequestStatus.find(
                              (item) => item.status === "감정완료"
                            )?.appraiseGram
                              ? appraisalRequestStatus.find(
                                  (item) => item.status === "감정완료"
                                )?.appraiseGram *
                                  (appraisalRequestStatus.find(
                                    (item) => item.status === "감정완료"
                                  )?.assetType === "GOLD"
                                    ? goldGramPrice
                                    : silverGramPrice)
                              : appraisalRequestStatus.find(
                                  (item) => item.status === "승인대기"
                                )?.appraiseGram *
                                  (appraisalRequestStatus.find(
                                    (item) => item.status === "승인대기"
                                  )?.assetType === "GOLD"
                                    ? goldGramPrice
                                    : silverGramPrice)
                          )}
                          원`
                            : ""
                        }`}
                </div>
                {status === "감정시작" ? (
                  <div>
                    <InputWrap>
                      <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={handleSaveInventory}
                      >
                        저장
                      </button>
                      <KendoInput
                        value={t1}
                        type="text"
                        autoComplete="off"
                        onChange={(e) => {
                          setT1(e.target.value);
                        }}
                        style={{ marginLeft: "5px" }}
                        placeholder="반려 사유 입력"
                      />
                      <button
                        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                        onClick={handleCancelAppraisal}
                      >
                        감정취소
                      </button>
                    </InputWrap>
                    {show && (
                      <Dialog
                        style={{ zIndex: 200 }}
                        title={"계산표 등록"}
                        onClose={handleSaveInventory}
                      >
                        <p
                          style={{
                            textAlign: "center",
                          }}
                        >
                          <div>
                            <Grid
                              scrollable={"none"}
                              data={silverProductList.filter(
                                (item) =>
                                  item.appraisalWeightGram !== null &&
                                  item.productWeightGram !== null &&
                                  item.productWeightGram !== "0" &&
                                  item.basicPct !== "0"
                              )}
                              cellRender={cellRender}
                            >
                              <GridColumn
                                field="purityTypeName"
                                title="구분"
                                width={130}
                              />
                              <GridColumn
                                field="name"
                                title="품목"
                                width={130}
                              />
                              <GridColumn
                                field="appraisalWeightGram"
                                title="환산중량"
                                width={130}
                              />
                            </Grid>
                            <div
                              style={{
                                marginTop: 10,
                                fontSize: 16,
                                fontFamily: "NotoSansKR-Bold",
                                fontWeight: "bold",
                              }}
                            >
                              교환완료 중량 : {numberWithCommas(totalGram)}g
                            </div>
                            <div style={{ marginTop: 10 }}>
                              <input
                                style={{
                                  width: "100%",
                                  height: 35,
                                  borderRadius: "0.2rem",
                                  fontFamily: "NotoSansKR-Bold",
                                  fontSize: "16px",
                                  border: "1px solid rgb(224,224,224)",
                                }}
                                value={t2}
                                type="text"
                                autoComplete="off"
                                onChange={(e) => {
                                  setT2(e.target.value);
                                }}
                                placeholder="감정내용 설명사항 입력(선택)"
                                disabled={status !== "감정시작"}
                              />
                            </div>
                          </div>
                        </p>
                        <DialogActionsBar>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={handleSaveInventory}
                          >
                            취소
                          </button>
                          <button
                            className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                            onClick={() => {
                              avoidDuplicateClicks(onSave);
                            }}
                          >
                            확인
                          </button>
                        </DialogActionsBar>
                      </Dialog>
                    )}
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <Grid
                style={{
                  width: "100%",
                  marginTop: 5,
                }}
                scrollable={"none"}
                data={silverProductList}
                cellRender={cellRender}
                onItemChange={onItemChange}
              >
                <GridColumn field="purityTypeName" title="구분" width={130} />
                <GridColumn field="name" title="품목" width={140} />
                <GridColumn
                  field="basicPct"
                  title="기준비율"
                  cell={basicPctCell}
                  width={120}
                />
                {status === "감정시작" ? (
                  <GridColumn
                    field="productWeightGram"
                    title="측정 실중량(g)"
                    cell={NumberInputCell}
                  />
                ) : (
                  <GridColumn
                    field="productWeightGram"
                    title="측정 실중량(g)"
                    width={80}
                  />
                )}
                <GridColumn
                  field="appraisalWeightGram"
                  title="환산 중량(g)"
                  cell={(props) => {
                    const { dataIndex } = props;
                    return (
                      <td style={{ width: 80, textAlign: "right" }}>
                        {silverProductList?.[dataIndex]?.appraisalWeightGram !==
                          null &&
                        silverProductList?.[dataIndex]?.productWeightGram !==
                          "" &&
                        silverProductList?.[dataIndex]?.basicPct !== "" &&
                        silverProductList?.[dataIndex]?.productWeightGram !==
                          null &&
                        silverProductList?.[dataIndex]?.productWeightGram !==
                          "-"
                          ? numberWithCommas(
                              silverProductList?.[dataIndex]
                                ?.appraisalWeightGram
                            )
                          : "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="inspectProductWeightGram"
                  title="검품 측정 실중량(g)"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 60, textAlign: "right" }}>
                        {dataItem.inspectProductWeightGram || "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="inspectWeightGram"
                  title="검품 환산 중량(g)"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 60, textAlign: "right" }}>
                        {dataItem.inspectWeightGram || "-"}
                      </td>
                    );
                  }}
                />
                <GridColumn
                  field="status"
                  title="검품 상태"
                  cell={(props) => {
                    const { dataItem } = props;
                    return (
                      <td style={{ width: 80, textAlign: "center" }}>
                        {dataItem.status
                          ?.replace("WAIT", "대기")
                          ?.replace("CANCEL", "취소")
                          ?.replace("COMPLETE", "완료") || "-"}
                      </td>
                    );
                  }}
                />
              </Grid>
            </div>
          )}
        </>
      )}
      {status === "접수" ||
      status === "접수취소" ||
      status === "감정시작" ||
      status === "감정반려" ||
      status === "승인대기" ||
      status === "감정완료" ? (
        <></>
      ) : (
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div style={{ alignSelf: "center" }}>
            ※ 출력 후 제품과 함께 본사로 송부해주시기 바랍니다.
          </div>
          <div>
            <Button onClick={handleSaveInventory}>인쇄</Button>
            {show && (
              <Dialog title={"계산표 인쇄"} onClose={handleSaveInventory}>
                <DialogForm>
                  <p
                    style={{
                      height: "fit-content",
                      textAlign: "center",
                    }}
                  >
                    <ComponentToPrint
                      ref={printComponentRef}
                      tableData={tableData}
                      goldProduct={goldProduct}
                      silverProduct={silverProduct}
                      type={assetType}
                      appraisalWeightGram={appraisalWeightGram}
                    />
                  </p>
                </DialogForm>
                <DialogActionsBar>
                  <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={handleSaveInventory}
                  >
                    취소
                  </Button>
                  <Button
                    className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
                    onClick={() => {
                      handleClickPrint();
                    }}
                  >
                    확인
                  </Button>
                </DialogActionsBar>
              </Dialog>
            )}
          </div>
        </div>
      )}
      {!isShop &&
        (serviceType === "BIZ_CONSIGNMENT" ? (
          <>
            <Grid
              style={{
                width: "100%",
                marginTop: 25,
              }}
              scrollable={"none"}
              data={tradeLogList}
              cellRender={cellRender}
            >
              <GridColumn field="total" title=" " width={130} />
              <GridColumn
                field="appraisalWeightGram"
                title="매입중량"
                width={140}
              />
              <GridColumn
                field="userTradeKrw"
                title="고객 지급금"
                width={100}
              />
              <GridColumn
                field="shopTradeFee"
                title="대리점 수수료"
                width={150}
              />
            </Grid>
            <Flex style={{ marginTop: 10, justifyContent: "end" }}>
              <Button
                disabled={detailData.orderTradeStatus !== "REQUEST_NONE"}
                onClick={() => {
                  handleOpenPopup("RETURN");
                }}
              >
                반송
              </Button>
            </Flex>
          </>
        ) : (
          <>
            <FlexContainer>
              <GridStyled
                isFirst
                style={{
                  width: "100%",
                  marginTop: 25,
                }}
                scrollable={"none"}
                data={tradeLogList}
                cellRender={cellRender}
              >
                <GridColumn field="total" title=" " width={130} />
                <GridColumn
                  field="appraisalWeightGram"
                  title="환산중량(g)"
                  width={140}
                />
                <GridColumn
                  field="contractWeightGram"
                  title="체결중량(g)"
                  width={100}
                />
                <GridColumn
                  field="waitOrderGram"
                  title="주문 대기(g)"
                  width={150}
                />
                <GridColumn
                  field="waitContractGram"
                  title="체결 대기(g)"
                  width={150}
                />
                <GridColumn
                  field="waitOrderPrice"
                  title="매도가격(원)"
                  width={150}
                />
              </GridStyled>
              <div>
                <div
                  style={{
                    height: 20,
                  }}
                >{`*출금 수수료 : ${numberWithCommas(
                  detailData?.withdrawalFee
                )}원`}</div>
                <GridStyled
                  isLast
                  style={{
                    width: "100%",
                    // borderLeft: "1px dotted ",
                    marginTop: 5,
                  }}
                  scrollable={"none"}
                  data={tradeLogList}
                  cellRender={cellRender}
                >
                  <GridColumn
                    field="withdrawComplete"
                    title="출금완료(원)"
                    width={150}
                  />
                  <GridColumn
                    field="withdrawNone"
                    title="미출금(원)"
                    width={150}
                  />
                  <GridColumn
                    field="withdrawConfirm"
                    title="승인대기(원)"
                    width={150}
                  />
                </GridStyled>
              </div>
            </FlexContainer>
            <Grid
              style={{
                width: "100%",
                marginTop: 10,
              }}
              scrollable={"none"}
              data={tradeHistory}
              cellRender={cellRender}
            >
              <GridColumn field="completedAt" title="거래일시" width={150} />
              <GridColumn field="tradeType" title="거래 형태" width={150} />
              <GridColumn field="orderType" title="주문 방식" width={150} />
              <GridColumn field="orderAmount" title="주문 금액" width={150} />
              <GridColumn field="userFee" title="수수료" width={150} />
              <GridColumn field="pureTradeKrw" title="결제 총액" width={150} />
              <GridColumn field="pricePerGram" title="체결단가" width={150} />
              <GridColumn field="tradeGram" title="결제 중량" width={150} />
              <GridColumn
                field="usingShopFee"
                title="대리점 수수료"
                width={150}
              />
            </Grid>
            <Flex style={{ marginTop: 10, justifyContent: "space-between" }}>
              <Flex style={{ gap: 5 }}>
                <Button
                  disabled={
                    detailData.orderTradeStatus === "REQUEST_NONE" ||
                    detailData.orderTradeStatus === "REQUEST_WAIT" ||
                    detailData.orderTradeStatus === "TRADE_COMPLETE" ||
                    detailData.orderTradeStatus === "RETURN_WAIT"
                  }
                  onClick={() => {
                    handleOpenPopup("CANCEL");
                  }}
                >
                  주문취소
                </Button>
                <Button
                  disabled={
                    detailData.orderTradeStatus === "WITHDRAW_CONFIRM" ||
                    detailData.orderTradeStatus === "RETURN_WAIT"
                  }
                  onClick={() => {
                    handleOpenPopup("WITHDRAW");
                  }}
                >
                  출금요청
                </Button>
                <Button
                  disabled={
                    detailData.orderTradeStatus === "TRADE_COMPLETE" ||
                    detailData.orderTradeStatus === "RETURN_WAIT"
                  }
                  onClick={() => {
                    handleOpenPopup("ORDER");
                  }}
                >
                  주문
                </Button>
              </Flex>
              <Button
                disabled={detailData.orderTradeStatus !== "REQUEST_NONE"}
                onClick={() => {
                  handleOpenPopup("RETURN");
                }}
              >
                반송
              </Button>
            </Flex>
            <div style={{ alignSelf: "center" }}>
              ※ 재주문과 출금 요청은 감정 후 1시간 이내에만 수정 가능합니다.
            </div>
          </>
        ))}
      <Grid
        style={{
          width: "100%",
          marginTop: 25,
        }}
        scrollable={"none"}
        data={parseData ? parseData : []}
        cellRender={cellRender}
      >
        <GridColumn field="createdAt" title="일시" width={130} />
        <GridColumn
          field="status"
          title="상태"
          width={100}
          cell={(props) => {
            const { dataItem } = props;
            return (
              <td>
                {dataItem?.status !== "-"
                  ? dataItem?.status
                  : dataItem?.stockStatus
                      ?.replace("CANCEL_WAIT", "입고취소 요청")
                      ?.replace("CANCEL", "입고취소")
                      ?.replace("WAIT", "입고 대기")
                      ?.replace("DELIVERY", "입고 배송")
                      ?.replace("TAKE", "재고 수령")
                      ?.replace("COMPLETE", "입고 완료")}
              </td>
            );
          }}
        />
        <GridColumn field="managerName" title="관리자" width={140} />
        <GridColumn field="assetType" title="자산" width={100} />
        <GridColumn field="appraiseGram" title="중량" width={150} />
        <GridColumn field="appraiseMemo" title="비고" />
      </Grid>
      <BizTransactionModal
        bizTransactionVisible={bizTransactionVisible}
        setBizTransactionVisible={setBizTransactionVisible}
        bizTradeSelectType={bizTradeSelectType}
        detailData={detailData}
        bizPurchasePrice={bizPurchasePrice}
        fetchDetail={fetchDetail}
      />
    </>
  );
};
