import React, { useState, useEffect, useMemo } from "react";
import { Route, Routes, Navigate } from "react-router-dom";
import DrawerLayout from "./components/layout/DrawerLayout";
// import "@progress/kendo-theme-default/dist/all.css";
// import Test from "./pages/test/Test";
// import Home from "./pages/Home";
import NotFound from "./pages/NotFound";
import MemberManagement from "./pages/member-management/MemberManagement";
import TradingAsset from "./pages/trading-asset/TradingAsset";
import Appraisal from "./pages/appraisal/Appraisal";
import GoodsWithdrawal from "./pages/goods-withdrawal/GoodsWithdrawal";
import SafeTrade from "./pages/safe-trade/SafeTrade";
import StoreManagement from "./pages/store-management/StoreManagement";
import SettlementManagement from "./pages/settlement-management/SettlementManagement";
import MyInfo from "./pages/my-info/MyInfo";
import Loading from "./components/common/Loading";
import Alert from "./components/common/Alert";
import ImageViewer from "./components/common/ImageViewer";
import { signIn } from "./store/authReducer";
import Login from "./pages/login/Login";
import { useSelector, useDispatch } from "react-redux";
import { STORAGE_KEY } from "./libs/consts";
import PasswordChange from "./pages/password-change/PasswordChange";
import Transaction from "./pages/transaction/Transaction";
import useRouteChangeTracker from "./hooks/useRouteChangeTracker";
import TwoFactor from "./pages/login/TwoFactor";
import TwoFactorRegister from "./pages/login/TwoFactorRegister";
import ResetAccount from "./pages/reset-account/ResetAccount";
import Penalty from "pages/penalty/Penalty";
import AuthGateway from "pages/AuthGateway";
import AcceptTerms from "pages/biz/AcceptTerms";
import AgreeSign from "pages/biz/AgreeSign";

function InitComponent() {
  const dispatch = useDispatch();
  const token = localStorage.getItem(STORAGE_KEY.accessToken);

  useEffect(() => {
    if (token) {
      dispatch(signIn(token));
    }
  }, []);

  return null;
}

const getRoutesByRole = (role) => {
  if (!role) return [];

  return (
    <>
      {role.includes("BIZ") && (
        <>
          <Route index element={<Navigate to="/appraisal" />} />
          <Route path="/penalty" element={<Penalty />} />
          <Route path="/shop" element={<StoreManagement />} />
          <Route path="/login*" element={<Navigate to="/appraisal" />} />
        </>
      )}
      {role.includes("SHOP") && (
        <>
          <Route index element={<Navigate to="/member" />} />
          <Route path="/member" element={<MemberManagement />} />
          <Route path="/goodswithdrawal" element={<GoodsWithdrawal />} />
          <Route path="/penalty" element={<Penalty />} />
          <Route path="/transaction" element={<Transaction />} />
          <Route path="/buysell" element={<TradingAsset />} />
          <Route path="/safe-trade" element={<SafeTrade />} />
          <Route path="/shop" element={<StoreManagement />} />
          <Route path="/login*" element={<Navigate to="/member" />} />
        </>
      )}
      {role.includes("ASSOCIATE") && (
        <>
          <Route index element={<Navigate to="/appraisal" />} />
          <Route path="/goodswithdrawal" element={<GoodsWithdrawal />} />
          <Route path="/login*" element={<Navigate to="/appraisal" />} />
        </>
      )}
    </>
  );
};

function App() {
  const { isSigned: authSigned, role } = useSelector((s) => s.auth);
  const token = localStorage.getItem(STORAGE_KEY.accessToken);
  const isSigned = token || authSigned;
  const [passwordCheck, setPasswordCheck] = useState("true");

  const routes = useMemo(() => getRoutesByRole(role), [role]);

  useRouteChangeTracker();

  return (
    <>
      <InitComponent />
      <Routes>
        {isSigned ? (
          passwordCheck === false ? (
            <>
              <Route
                path="/password-check"
                element={<PasswordChange onCheckPassword={setPasswordCheck} />}
              />
              <Route path="*" element={<Navigate to="/password-check" />} />
            </>
          ) : (
            <Route path="/" element={<DrawerLayout />}>
              {routes}
              <Route path="/auth/gateway" element={<AuthGateway />} />
              <Route path="/appraisal" element={<Appraisal />} />
              <Route path="/adjustment" element={<SettlementManagement />} />
              <Route path="/manager" element={<MyInfo />} />
              <Route path="*" element={<NotFound />} />
            </Route>
          )
        ) : (
          <>
            <Route path="/reset-account" element={<ResetAccount />} />
            <Route
              path="/login"
              element={<Login onCheckPassword={setPasswordCheck} />}
            />
            <Route
              path="/login/two-factor"
              element={<TwoFactor onCheckPassword={setPasswordCheck} />}
            />
            <Route
              path="/login/two-factor/register"
              element={<TwoFactorRegister onCheckPassword={setPasswordCheck} />}
            />
            <Route path="/talk/acceptTerms" element={<AcceptTerms />} />
            <Route path="/agree/sign/:uuid" element={<AgreeSign />} />
            <Route index path="*" element={<Navigate to="/login" />} />
          </>
        )}
      </Routes>
      <Alert />
      <ImageViewer />
      <Loading />
    </>
  );
}

export default App;
